import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';

// Import components
import FIFA from './components/FIFA';
import PES from './components/PES';
import GTA5 from './components/GTA5';
import Skyrim from './components/Skyrim';
import SignUp from './components/SignUp';

// Import images
import fifaImage from './components/images/fifa.webp';
import pesImage from './components/images/pes.webp';
import gta5Image from './components/images/gta5.webp';
import skyrimImage from './components/images/skyrim.webp';
import logoImage from './components/images/logo.webp'; 

import AnimatedText from './components/animations/AnimatedText';

const App = () => {
  const [showSubscribeOverlay, setShowSubscribeOverlay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [attemptFailed, setAttemptFailed] = useState(false); // Track if first attempt failed
  const [loadingFinished, setLoadingFinished] = useState(false); // Track when loading finishes

  const statusMessages = [
    'Please make sure you have subscribed to our channel.',
    'This ensures you get access to exclusive content and mods!',
    'Don’t forget, subscribing helps us bring more features to you.',
    'Subscribing is completely free!',
    'You can only proceed once you’re subscribed, it won’t work otherwise.',
  ];

  useEffect(() => {
    const hasVisited = localStorage.getItem('subscribed');
    if (!hasVisited) {
      setShowSubscribeOverlay(true);
    }
  }, []);

  const handleSubscription = () => {
    setIsLoading(true);
    setAttemptFailed(false);
    setLoadingFinished(false);

    let messageIndex = 0;
    const messageInterval = setInterval(() => {
      setStatusMessage(statusMessages[messageIndex]);
      messageIndex = (messageIndex + 1) % statusMessages.length;
    }, 2500);

    // Simulate verification failure after 10 seconds
    setTimeout(() => {
      clearInterval(messageInterval);
      setIsLoading(false);
      setAttemptFailed(true); // Simulate failure
    }, 10000);
  };

  const handleRetry = () => {
    setIsLoading(true);
    setAttemptFailed(false);
    setLoadingFinished(false); // Reset for re-attempt

    let messageIndex = 0;
    const messageInterval = setInterval(() => {
      setStatusMessage(statusMessages[messageIndex]);
      messageIndex = (messageIndex + 1) % statusMessages.length;
    }, 2500);

    // Simulate success on retry after 10 seconds
    setTimeout(() => {
      clearInterval(messageInterval);
      setIsLoading(false);
      setLoadingFinished(true); // Show success and the "I Have Subscribed" button
    }, 10000);
  };

  const handleCloseOverlay = () => {
    localStorage.setItem('subscribed', 'true');
    setShowSubscribeOverlay(false);
  };

  const mods = [
    { name: 'FIFA', imageUrl: fifaImage, path: '/fifa' },
    { name: 'PES', imageUrl: pesImage, path: '/pes' },
    { name: 'GTA5', imageUrl: gta5Image, path: '/gta5' },
    { name: 'Skyrim', imageUrl: skyrimImage, path: '/skyrim' }
  ];

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <div className="App">
      {/* Subscription Overlay */}
      {showSubscribeOverlay && (
        <div className="subscription-overlay">
          <div className="subscription-content">
            {isLoading ? (
              <div className="loading-animation">
                <div className="spinner"></div>
                <p>Verifying Subscription.....</p>
                <p className="status-message">{statusMessage}</p>
              </div>
            ) : attemptFailed ? (
              <>
                <h3>Verification Failed</h3>
                <p>Please subscribe and then re-attempt verification.</p>
                <button className="retry-button" onClick={handleRetry}>
                  Re-attempt Check
                </button>
              </>
            ) : loadingFinished ? (
              <>
                <h3>Thanks For Subscribing!</h3>
                <h5 className="final-caption">
                  Click the button below to proceed.
                </h5>
                <button className="close-overlay-button" onClick={handleCloseOverlay}>
                  I Have Subscribed
                </button>
              </>
            ) : (
              <>
                <h3>Oops! Page Locked</h3>
                <h4>Sorry, but to continue, you need to subscribe on YouTube first</h4>
                <a
                  href="https://www.youtube.com/@tnkmods?sub_confirmation=1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="subscribe-button"
                  onClick={handleSubscription}
                >
                  Subscribe Now
                </a>
              </>
            )}
          </div>
        </div>
      )}

      {/* Add the navigation bar */}
      <div className={showSubscribeOverlay ? "blur-background" : ""}>
        <header className="navbar">
          <img src={logoImage} alt="Logo" className="navbar-logo" />
          <AnimatedText />
          <nav className="navbar-links">
            <Link to="/" className="home-link">Home</Link>
            <Link to="/signup" className="auth-link">Join</Link>
          </nav>
        </header>

        {isHomePage && (
          <>
            <h1>Select The Game</h1>
            <div className="card-container">
              {mods.map((mod) => (
                <div key={mod.name} className="card" style={{ backgroundImage: `url(${mod.imageUrl})` }}>
                  <div className="overlay">
                    <h2>{mod.name}</h2>
                  </div>
                  <Link to={mod.path}>
                    <button className="card-button">Go to {mod.name} Mods</button>
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Define Routes */}
        <Routes>
          <Route path="/fifa" element={<FIFA />} />
          <Route path="/fifa/:version" element={<FIFA />} /> {/* Route for specific versions */}
          <Route path="/pes" element={<PES />} />
          <Route path="/pes/:version" element={<PES />} />
          <Route path="/gta5" element={<GTA5 />} />
          <Route path="/skyrim" element={<Skyrim />} />
          {/* Routes for Auth pages */}
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </div>
    </div>
  );
};

// Wrap App with Router
const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
